* {
    font-family: "Roboto", sans-serif;
    box-sizing: border-box;
    margin: 0;
}

body {
    line-height: 1.5;
}

.container {
    width: 70%;
    margin: 0 auto;
}

h2 {
    font-size: 2rem;
}

@media (max-width: 575px) {
    .container {
        width: 90%;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .container {
        width: 90%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .container {
        width: 85%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .container {
        width: 80%;
    }
}

@media (min-width: 1200px) {
}
